* {
  font-family: "Futura", serif;
  font-weight: 500;
  font-style: normal;
  color: #565656;
  text-wrap: pretty;
}

body {
  background-color: #f5f5f5;
  margin: 0;
}

a {
  text-decoration: none;
}

[data-rmiz-btn-unzoom] {
  display: none;
}

p {
  text-wrap: pretty;
}

.MuiDataGrid-main > div:last-child {
  color: transparent !important;
}
